import { useMemo, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import styles from "./CartHeader.module.css";
import cartIcon from "/assets/topbar/Cart_Open.svg";
import { useGetEntitiesQuery } from "../Entities/apis/entityApi-slice";
import { LBL__CART } from "../../../consts/TextConstants";
import {
  useGetCartDataQuery,
  useLazyGetCartDataQuery,
} from "./apis/cartApi-slice";
import { cartActions } from "./reducers/cart-slice";
import Dropdown from "../../UI/Dropdown/Dropdown";
import CartEntityDropDown from "./CartEntityDropDown";

const CartHeader = () => {
  const dispatch = useDispatch();
  // Fetch entities and primary entity from Redux store
  const { entities, primaryEntity } = useSelector((state) => state.entity);
  const { selectedEntity, cartPendingList } = useSelector(
    (state) => state.cart
  );

  // Fetch entities and primary entity data using RTK Query
  const { isLoading } = useGetEntitiesQuery();

  useEffect(() => {
    if (primaryEntity) {
      dispatch(cartActions.setSelectedEntity(primaryEntity));
    }
  }, [primaryEntity]);

  const { isLoading: isLoadingCart } = useGetCartDataQuery(
    { entity_id: selectedEntity?.entityId },
    {
      skip: !selectedEntity,
      refetchOnMountOrArgChange: true,
    }
  );

  const [getCartData, { isLoading: loadingGetCartData }] =
    useLazyGetCartDataQuery();

  // Handle entity selection change
  const handleEntityChange = async (entity) => {
    try {
      // const entity = entityOptions[value];
      dispatch(cartActions.setSelectedEntity(entity));

      await getCartData({ entity_id: entity.entityId }).unwrap();
    } catch (error) {
      console.error("Error fetching cart data:", error);
    }
  };

  // Memoize entity options to optimize performance
  const entityOptions = useMemo(() => {
    if (!entities || entities.length === 0) return {};
    return entities.reduce((acc, entity) => {
      acc[entity.entityName] = entity;
      return acc;
    }, {});
  }, [entities]);

  return (
    <div className={styles.container}>
      {/* Cart icon */}
      <img src={cartIcon} alt="" />

      {/* Cart header title */}
      <p className={styles.header}>{LBL__CART}</p>

      <div className={styles.entity_dropdown_container}>
        <CartEntityDropDown onChange={handleEntityChange} />

        {/* Placeholder for pending cart items */}
        {cartPendingList?.length > 0 && (
          <div className={styles.pending_cart_items}></div>
        )}
      </div>
    </div>
  );
};

export default CartHeader;
