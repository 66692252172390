import ServiceCardWrapper from "./ServiceCardWrapper";
import styles from "./ServicesHomeCard.module.css";
import Button from "../../../UI/Button/Button";
import AddOverlayService from "../AddService/AddOverlayServices.jsx";
import { useState } from "react";
import AddTaxComplianceOverlay from "../AddCompliance/AddTaxComplianceOverlay";
import ArrowRight from "/assets/common/Arrow_Gray.svg";
import { subscriptionTabs } from "../../../../consts/TextConstants";
import { useDispatch } from "react-redux";
import { subscriptionsActions } from "../reducers/subscriptionsSlice";

const ServicesHomeCard = ({
  title,
  shortDescription,
  price,
  activeTab,
  complianceId,
}) => {
  const [showOverlay, setShowOverlay] = useState(null);
  const dispatch = useDispatch();
  // Event handler to toggle visibility of the overlay/modal
  const handleCardClick = () => {
    setShowOverlay(activeTab);
  };
  // Event handler to close the overlay/modal
  const handleClose = () => {
    dispatch(subscriptionsActions.clearSelectedCompliance());
    setShowOverlay(null);
  };

  // Define the header based on the activeTab prop
  const header =
    activeTab === subscriptionTabs.COMPLIANCE ? (
      // TAX COMPLIANCE card style , entityType only extra thing
      <div className={styles.header_style}>
        <div className={styles.header_first_half}>
          <h2 className={styles.header_title}>{title}</h2>
          <p className={styles.Tax_header_shortDescription}>
            {shortDescription}
          </p>
        </div>
      </div>
    ) : (
      // SERVICES card title, short para cont not entityType
      <div className={styles.header_services_style}>
        <h2 className={styles.header_title}>{title}</h2>
        <p className={styles.header_shortDescription}>{shortDescription}</p>
      </div>
    );

  // Footer section containing the price and a button to add a service
  const footer = (
    <div className={styles.footer_style}>
      <p className={styles.price}>${price}</p>
      <div className={styles.forward_cont}>
        <img src={ArrowRight} className={styles.forward_img} />
      </div>
    </div>
  );

  return (
    <>
      <ServiceCardWrapper
        header={header}
        footer={footer}
        className={styles.servicecard_container}
        onClick={handleCardClick}
      />

      {/* Show the overlay when showOverlay is true */}
      {showOverlay === subscriptionTabs.SERVICES && (
        <AddOverlayService onClose={handleClose} title={title} price={price} />
      )}

      {showOverlay === subscriptionTabs.COMPLIANCE && (
        <AddTaxComplianceOverlay
          onClose={handleClose}
          title={title}
          price={price}
          shortDescription={shortDescription}
          complianceId={complianceId}
        />
      )}
    </>
  );
};

export default ServicesHomeCard;
