import { createSlice, createSelector } from "@reduxjs/toolkit";

const initialState = {
  cartItems: [],
  selectedEntity: null,
  discountAmount: 0, // Example discount
  discountCode: "", //DISCOUNT CODE
  cartPendingList: [],
};

const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    deleteItem: (state, action) => {
      state.cartItems = state.cartItems.filter(
        (_, index) => index !== action.payload
      );
    },
    setSelectedEntity: (state, action) => {
      state.selectedEntity = action.payload;
    },
    setCartItems: (state, action) => {
      let cartItems = [];
      action.payload?.cartItems?.forEach((item) => {
        let complianceItems = {};
        if (item?.product_category === "compliance") {
          complianceItems = {
            year: item?.cart_metadata?.subscription_inputs
              ?.subscription_tax_year,
          };
        }
        cartItems.push({
          cartId: item?.cart_id,
          productId: item?.product_id,
          productName: item?.product_name,
          price: item?.product_price,
          description: item?.product_description,
          ...complianceItems,
        });
      });
      state.cartItems = cartItems;
    },
    setCartPendingList: (state, action) => {
      let cartPendingList = [];
      action.payload?.entities?.forEach((item) => {
        cartPendingList.push(item.entity);
      });
      state.cartPendingList = cartPendingList;
    },
  },
});

export const selectFilteredCartItems = (state) => {
  const { cartItems, selectedEntity } = state.cart;
  return selectedEntity
    ? cartItems.filter((item) => item.entityName === selectedEntity)
    : cartItems;
};

// Selector to calculate total amount, discount, and total payable
export const selectCartSummary = createSelector(
  [(state) => state.cart.cartItems, (state) => state.cart.discountAmount],
  (cartItems, discountAmount) => {
    const totalAmount = cartItems.reduce(
      (sum, item) => sum + parseFloat(item.price),
      0
    );
    const totalPayable = totalAmount - discountAmount;
    return { totalAmount, discountAmount, totalPayable };
  }
);

export const cartActions = cartSlice.actions;
export default cartSlice.reducer;
