//   kr 6
import { useState, useEffect, useMemo } from "react";
import styles from "./ServiceForm.module.css";
import YearDropdown from "../../../UI/Dropdown/YearDropDown";
import Dropdown from "../../../UI/Dropdown/Dropdown";
import arrowDown from "/assets/common/ArrowDown_Filled.svg";
import QuoteSuccessDialog from "./QuoteSuccessDialog";
import { MONTH_NAMES_SHORT } from "../../../../consts/Constants";
import { useDispatch } from "react-redux";
import { serviceActions } from "../reducers/service-slice";
import Button from "../../../UI/Button/Button";
import { useSelector } from "react-redux";
import {
  useLazyGetTRCYearsQuery,
  useCreateQuoteServiceMutation,
} from "../apis/serviceApi-slice";
import LoadingComponent from "../../../UI/LoadingIndicator/LoadingComponent";
import ErrorComponent from "../../../UI/Error/ErrorComponent";
import { useGetEntitiesQuery } from "../../Entities/apis/entityApi-slice";

const ServiceForm = ({
  selectedService: serviceToDisplay,
  onAdd,
  onClose,
  onAddQuoteSuccess,
}) => {
  const dispatch = useDispatch();
  const [trcYears, setTrcYears] = useState([]);
  const [selectedYear, setSelectedYear] = useState("");
  const [serviceName, setServiceName] = useState("");
  const [description, setDescription] = useState("");
  const [auditFromYear, setAuditFromYear] = useState("");
  const [auditFromMonth, setAuditFromMonth] = useState("");
  const [auditToYear, setAuditToYear] = useState("");
  const [auditToMonth, setAuditToMonth] = useState("");
  const [isAddButtonEnabled, setIsAddButtonEnabled] = useState(false);
  const [isGetQuoteButtonEnabled, setIsGetQuoteButtonEnabled] = useState(false);
  const [isQuotePopupOpen, setIsQuotePopupOpen] = useState(false);
  const [selectedEntity, setSelectedEntity] = useState("");
  const showGetQuoteOption = useMemo(() => {
    return (
      !serviceToDisplay?.servicePrice || serviceToDisplay?.servicePrice === 0
    );
  }, [serviceToDisplay]);

  const { entities } = useSelector((state) => state.entity);
  const currentYear = new Date().getFullYear();

  const [
    getTrcYears,
    { isFetching: isTrcYearsLoading, isError: isTrcYearsError },
  ] = useLazyGetTRCYearsQuery();

  const [createQuoteService, { isLoading }] = useCreateQuoteServiceMutation();

  const { isLoading: isEntitiesLoading, isError: isEntitiesError } =
    useGetEntitiesQuery();

  const entityOptions = useMemo(
    () =>
      entities?.reduce((acc, entity) => {
        if (entity.country === "United States") {
          acc[entity.entityName] = entity.entityId;
        }
        return acc;
      }, {}),
    [entities]
  );

  const incorporationYear = useMemo(
    () =>
      selectedEntity
        ? new Date(
            entities.find(
              (entity) => entity.entityId === entityOptions[selectedEntity]
            )?.incorporationDate
          ).getFullYear()
        : 2022,
    [entities, entityOptions, selectedEntity]
  );

  const getServiceConfigs = (serviceName) => {
    switch (serviceName) {
      case "Tax Residency Certificate":
        return { selectedYear };
      case "Audit & Review Report":
        return {
          auditFromYear,
          auditFromMonth,
          auditToYear,
          auditToMonth,
        };
      case "Other":
        return { serviceName, description };
      default:
        return {};
    }
  };
  const isAuditPeriodValid = () => {
    if (!auditFromYear || !auditFromMonth || !auditToYear || !auditToMonth) {
      return false;
    }

    // Convert months to numbers for comparison
    const fromMonthIndex = MONTH_NAMES_SHORT.indexOf(auditFromMonth);
    const toMonthIndex = MONTH_NAMES_SHORT.indexOf(auditToMonth);

    if (parseInt(auditFromYear) > parseInt(auditToYear)) {
      return false; // 'From Year' cannot be greater than 'To Year'
    }

    if (parseInt(auditFromYear) === parseInt(auditToYear)) {
      // Same year: 'From Month' should be less than 'To Month'
      if (fromMonthIndex >= toMonthIndex) {
        return false;
      }
    }

    return true;
  };

  useEffect(() => {
    const fetchTrcYears = async () => {
      if (selectedEntity) {
        try {
          const trcYears = await getTrcYears({
            entityId: entityOptions[selectedEntity],
          }).unwrap();
          setTrcYears(trcYears);
        } catch (error) {
          console.error("Failed to fetch TRC years:", error);
        }
      }
    };
    fetchTrcYears();
  }, [selectedEntity]);

  useEffect(() => {
    if (serviceToDisplay?.serviceName === "Tax Residency Certificate") {
      setIsAddButtonEnabled(selectedYear && selectedEntity);
    } else if (serviceToDisplay?.serviceName === "Audit & Review Report") {
      setIsAddButtonEnabled(
        auditFromYear &&
          auditFromMonth &&
          auditToYear &&
          auditToMonth &&
          isAuditPeriodValid() &&
          selectedEntity
      );
    } else if (serviceToDisplay?.serviceName === "Other") {
      setIsGetQuoteButtonEnabled(serviceName && description && selectedEntity);
    } else {
      setIsAddButtonEnabled(!!selectedEntity);
    }
  }, [
    selectedYear,
    auditFromYear,
    auditFromMonth,
    auditToYear,
    auditToMonth,
    serviceName,
    description,
    serviceToDisplay,
    selectedEntity,
  ]);

  const handleAddClick = () => {
    const data = getServiceConfigs(serviceToDisplay.serviceName);
    const serviceData = {
      entityId: entityOptions[selectedEntity],
      serviceId: serviceToDisplay.serviceId,
      serviceName: serviceToDisplay.serviceName,
      servicePrice: serviceToDisplay.servicePrice,
      serviceDescription:
        serviceToDisplay.serviceName === "Other"
          ? description
          : serviceToDisplay.serviceDescription,
      data,
    };
    onAdd(serviceData);
  };

  const handleGetQuoteClick = async () => {
    try {
      const serviceInput =
        serviceToDisplay.serviceName === "Tax Residency Certificate"
          ? { year: serviceToDisplay?.data?.selectedYear || "N/A" }
          : serviceToDisplay.serviceName === "Audit & Review Report"
          ? {
              auditFromYear: serviceToDisplay?.data?.auditFromYear || "N/A",
              auditFromMonth: serviceToDisplay?.data?.auditFromMonth || "N/A",
              auditToYear: serviceToDisplay?.data?.auditToYear || "N/A",
              auditToMonth: serviceToDisplay?.data?.auditToMonth || "N/A",
            }
          : { };

      const postData = {
        subscription_inputs: serviceInput,
        entity_id: entityOptions[selectedEntity],
        product_id: serviceToDisplay.serviceId,
      };
      await createQuoteService({
        ...postData,
      }).unwrap();
      setIsQuotePopupOpen(true);
    } catch (error) {
      console.error("Failed to get quote:", error);
    }
  };
  const handleClosePopup = () => {
    setIsQuotePopupOpen(false);
    onAddQuoteSuccess();
  };

  if (!serviceToDisplay) return null;
  return (
    <div className={styles.right_container}>
      <div className={styles.dropdown_entity_cont}>
        <Dropdown
          placeholder="Entity Name"
          options={Object.keys(entityOptions)}
          selected={selectedEntity}
          onSelect={setSelectedEntity}
          id="entitySelect"
          dropdownSize={styles.dropdown__entity}
          dropdownListSize={styles.dropdown__entity_list}
          arrow={arrowDown}
        />
      </div>
      <div className={styles.right_cont_header}>
        <p className={styles.description}>
          {serviceToDisplay.serviceDescription}
        </p>
        {!showGetQuoteOption && (
          <div className={styles.price}>${serviceToDisplay.servicePrice}</div>
        )}
      </div>
      <div className={styles.right_cont_middle}>
        {serviceToDisplay.serviceName === "Tax Residency Certificate" && (
          <>
            {isTrcYearsLoading && <LoadingComponent />}
            {isTrcYearsError && (
              <ErrorComponent errorMessage="Something went wrong. Please try again" />
            )}
            {trcYears && trcYears?.years?.length > 0 && (
              <div className={styles.first_pop_middle_cont}>
                <label htmlFor="yearSelect" className={styles.first_pop_label}>
                  Year
                </label>
                <YearDropdown
                  placeholder=""
                  // start={incorporationYear}
                  // end={currentYear + 1}
                  month={""}
                  showMonth={false}
                  customOptions={trcYears?.years || []}
                  selected={selectedYear}
                  onSelect={setSelectedYear}
                  id="yearSelect"
                  dropdownSize={styles.year_dropdown}
                  className={styles.dropdown}
                  arrow={arrowDown}
                  listStyle={styles.dropDownList}
                  listItemStyle={styles.list_item}
                  headerStyle={styles.dropdownHeader}
                />
              </div>
            )}
            {trcYears && trcYears?.years?.length === 0 && (
              <ErrorComponent errorMessage="No years found" />
            )}
          </>
        )}
        {serviceToDisplay.serviceName === "Audit & Review Report" && (
          <div className={styles.Acc_main_cont}>
            <div className={styles.first_pop_middle_cont_years_cont}>
              <div className={styles.from_cont}>
                <div className={styles.from}>
                  <label
                    htmlFor="fromYearSelect"
                    className={styles.first_pop_label}
                  >
                    FROM
                  </label>
                  <YearDropdown
                    placeholder="Year"
                    start={incorporationYear}
                    end={currentYear}
                    month={""}
                    showMonth={false}
                    selected={auditFromYear}
                    onSelect={setAuditFromYear}
                    id="fromYearSelect"
                    dropdownSize={styles.year_dropdown}
                    className={styles.dropdown}
                    arrow={arrowDown}
                    listStyle={styles.dropDownList}
                    listItemStyle={styles.list_item}
                    headerStyle={styles.dropdownHeader}
                  />
                </div>
                <div className={styles.month_cont}>
                  <Dropdown
                    className={styles.dropdown}
                    placeholder="Month"
                    options={MONTH_NAMES_SHORT}
                    selected={auditFromMonth}
                    onSelect={setAuditFromMonth}
                    id="fromMonthSelect"
                    dropdownSize={styles.year_dropdown}
                    dropdownListSize={styles.dropdown_list}
                    arrow={arrowDown}
                  />
                </div>
              </div>

              <div className={styles.from_cont}>
                <div className={styles.from}>
                  <label
                    htmlFor="toYearSelect"
                    className={styles.first_pop_label}
                  >
                    TO
                  </label>
                  <YearDropdown
                    placeholder="Year"
                    start={incorporationYear}
                    end={currentYear}
                    month={""}
                    showMonth={false}
                    selected={auditToYear}
                    onSelect={setAuditToYear}
                    id="toYearSelect"
                    dropdownSize={styles.year_dropdown}
                    className={styles.dropdown}
                    arrow={arrowDown}
                    listStyle={styles.dropDownList}
                    listItemStyle={styles.list_item}
                    headerStyle={styles.dropdownHeader}
                  />
                </div>
                <div className={styles.month_cont}>
                  <Dropdown
                    className={styles.dropdown}
                    placeholder="Month"
                    options={MONTH_NAMES_SHORT}
                    selected={auditToMonth}
                    onSelect={setAuditToMonth}
                    id="toMonthSelect"
                    dropdownSize={styles.year_dropdown}
                    dropdownListSize={styles.dropdown_list}
                    arrow={arrowDown}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
        {serviceToDisplay.serviceName === "Other" && (
          <div className={styles.o}>
            <div className={styles.other_ser_name}>
              <h3 className={styles.other_service_name}>Service name</h3>
              <div className={styles.other_service_input}>
                <input
                  type="text"
                  value={serviceName}
                  placeholder="Enter service name..."
                  onChange={(e) => setServiceName(e.target.value)}
                />
              </div>
            </div>
            <div className={styles.text_area_container}>
              <textarea
                className={styles.other_service_textbox}
                placeholder="Enter description..."
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </div>
          </div>
        )}
      </div>
      <div className={styles.right_cont_footer}>
        <Button
          className={styles.ui__button}
          buttonstyle="default-blue"
          onClick={showGetQuoteOption ? handleGetQuoteClick : handleAddClick}
          disabled={!isAddButtonEnabled || isLoading}
          isLoading={isLoading}
        >
          {showGetQuoteOption ? "Get Quote" : "ADD"}
        </Button>
      </div>
      {isQuotePopupOpen && <QuoteSuccessDialog onClose={handleClosePopup} />}
    </div>
  );
};

export default ServiceForm;
