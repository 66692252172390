import { forwardRef } from "react";
import styles from "./Button.module.css";
import { motion } from "framer-motion";
import LoadingIndicator from "../LoadingIndicator/LoadingIndicator";

const Button = forwardRef(
  (
    { children, className, buttonstyle, isLoading, hasHoverStyles, ...props },
    ref
  ) => {
    let classes = `${styles.ui__button}`;
    let hoverStyles = {};
    const defaultHoverStyles = {
      backgroundColor: "rgba(0,0,0,0)",
      color: "#0047FF",
      border: "3px solid #0047FF",
    };

    switch (buttonstyle) {
      case "white-circle-icon":
        classes = `${classes} ${styles.white_circle_icon}`;
        break;
      case "default-blue":
        classes = `${classes} ${styles.default_blue}`;
        if (hasHoverStyles) {
          hoverStyles = defaultHoverStyles;
        }
        break;
      case "default-blue-outline":
        classes = `${classes} ${styles.default_blue_outline}`;
        if (hasHoverStyles) {
          hoverStyles = { ...defaultHoverStyles };
        }
        break;
      case "fixed_width_blue_login":
        classes = `${classes} ${styles.fixed_width_blue_login}`;
        hoverStyles = defaultHoverStyles;
        break;
      case "white-box-shadow":
        classes = `${classes} ${styles.white_box_shadow}`;
        break;
      case "yellow-filled":
        classes = `${classes} ${styles.yellow_filled}`;
        break;
      case "grey-outline":
        classes = `${classes} ${styles.grey_outline}`;
        break;
      case "light-grey-outline":
        classes = `${classes} ${styles.light_grey_outline}`;
        break;
      case "light-grey-outline-dark-selected":
        classes = `${classes} ${styles.light_grey_outline_dark_selected}`;
        break;
      case "green-filled":
        classes = `${classes} ${styles.green_filled}`;
        break;
      case "default-blue-translucent":
        classes = `${classes} ${styles.default_blue_translucent}`;
        break;
      case "light-grey-dark-selected":
        classes = `${classes} ${styles.light_grey_dark_selected}`;
        break;
      default:
        break;
    }

    classes = `${classes} ${className}`;

    return (
      <motion.button
        ref={ref}
        whileHover={hoverStyles}
        transition={{ duration: 0.3 }}
        className={classes}
        {...props}
      >
        {isLoading && <LoadingIndicator />}
        {children}
      </motion.button>
    );
  }
);

export default Button;
