import styles from "./ComplianceInputSelector.module.css";
import EditInputItem from "../../Edit/EditInputItem";
import { isArray } from "lodash";
import { getFilteredUSStatesList } from "../../../../utils/listUSStates";
import { getCountriesList } from "../../../../utils/common-utils";

const countriesList = getCountriesList();

const ComplianceInputSelector = ({
  inputDetails,
  entityType,
  onChange,
  value,
  className,
  showError,
  errorMessage,
}) => {
  switch (inputDetails?.inputType) {
    case "drop_down":
      if (
        inputDetails?.fieldValue === "state_list" ||
        inputDetails?.fieldValue === "country_list"
      ) {
        const isCountryList = inputDetails?.fieldValue === "country_list";
        const suggestions = isCountryList ? countriesList : null;
        const filteredListFunc = isCountryList ? null : getFilteredUSStatesList;
        const customFilter = isCountryList ? false : true;

        const handleChange = (item) => {
          onChange(isCountryList ? item?.value : item?.key);
        };
        return (
          <EditInputItem
            key={`${inputDetails?.fieldName}-${inputDetails?.inputType}`}
            name={inputDetails?.fieldName}
            type="autocomplete"
            labelTitle={inputDetails?.label}
            value={value || ""}
            defaultValue={value}
            customFilter={customFilter}
            filteredListFunc={filteredListFunc}
            suggestions={suggestions}
            onChange={handleChange}
            showError={showError}
            errorMessage={errorMessage}
          />
        );
      }
      let options = isArray(inputDetails?.fieldValue)
        ? inputDetails?.fieldValue
        : [];

      if (inputDetails?.fieldName === "irs_tax_classification") {
        options = inputDetails?.fieldValue[0][entityType] || [];
      }
      return (
        <EditInputItem
          key={`${inputDetails?.fieldName}-${inputDetails?.inputType}`}
          name={inputDetails?.fieldName}
          type="dropdown"
          labelTitle={inputDetails?.label}
          value={value}
          options={options}
          onSelect={onChange}
          className={`${styles.input_dropdown} ${className}`}
          showError={showError}
          errorMessage={errorMessage}
        />
      );
    default:
      return null;
  }
};

export default ComplianceInputSelector;
