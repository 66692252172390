//this component 100%
import {
    LBL__COI_AMENDAMENT, LBL__BOARD_RESOLUTION, LBL__FINANCIAL_MODELLING,
    LBL__FINANCIAL_AUDIT, LBL__GROUP_CONSOLIDATION, LBL__GAAP_FINANCIALS, LBL__EFTPS_REGISTRATION,
    LBL__TAX_RESIDENCY_CERTIFICATE, ERR__SELECTED_ENTITY_REQUIRED, ERR__DESCRIPTION_REQUIRED, ERR__FROM_DATE_REQUIRED,
    ERR__TO_DATE_REQUIRED, ERR__DATE_RANGE,
} from "../../../../consts/TextConstants";
import { useSelector } from "react-redux";
import { useGetEntitiesQuery } from "../../Entities/apis/entityApi-slice";
import styles from "./ServiceForm.module.css";
import EditInputItem from "../../Edit/EditInputItem";
import { useMemo, useState, useEffect } from "react";
import CloseWhite from "/assets/common/Close_Blue.svg";

const ServiceForm = ({ title, handleInputChange, showErrors }) => {
    const [fromDate, setFromDate] = useState(new Date());
    const [toDate, setToDate] = useState(new Date());
    const [selectedEntity, setSelectedEntity] = useState("");
    const [description, setDescription] = useState("");
    const [errors, setErrors] = useState({});
    const [selectedEntities, setSelectedEntities] = useState([]);
    const [selectedYears, setSelectedYears] = useState([]);

    const [showEntityError, setShowEntityError] = useState(false);

    const { entities } = useSelector((state) => state.entity);
    const { isError: isEntitiesError } = useGetEntitiesQuery();

    const validateForm = () => {
        let newErrors = {};
        if (!selectedEntity) newErrors.selectedEntity = ERR__SELECTED_ENTITY_REQUIRED;

        if ([LBL__COI_AMENDAMENT, LBL__BOARD_RESOLUTION, LBL__FINANCIAL_MODELLING].includes(title) && !description) {
            newErrors.description = ERR__DESCRIPTION_REQUIRED;
        }

        if ([LBL__FINANCIAL_AUDIT, LBL__GROUP_CONSOLIDATION, LBL__GAAP_FINANCIALS].includes(title)) {
            if (!fromDate) newErrors.fromDate = ERR__FROM_DATE_REQUIRED;
            if (!toDate) newErrors.toDate = ERR__TO_DATE_REQUIRED;
            if (new Date(toDate) <= new Date(fromDate)) newErrors.dateRange = ERR__DATE_RANGE;
        }
        setErrors(newErrors);
        const isValid = Object.keys(newErrors).length === 0;
        handleInputChange("isFormValid", isValid);
        return isValid;
    };

    // Validate the form only when necessary (on form data change)
    useEffect(() => {
        if (showErrors) {
            validateForm();
        }
        // validateForm();
    }, [selectedEntity, description, fromDate, toDate, showErrors]);

    const currentYear = new Date().getFullYear();

    const entityOptions = useMemo(() => {
        if (!entities || entities.length === 0) return {};
        return entities.reduce((acc, entity) => {
            acc[entity.entityName] = entity.entityId;
            return acc;
        }, {});
    }, [entities]);

    const handleEntityChange = (entity) => {
        setSelectedEntity(entity);
        if (!selectedEntities.includes(entity)) {
            setSelectedEntities((prev) => [...prev, entity]);
        }
        handleInputChange("selectedEntity", entity);
        handleInputChange("selectedEntities", [...selectedEntities, entity]);
    };

    const onRemoveEntity = (entityToRemove) => {
        const updatedEntities = selectedEntities.filter((entity) => entity !== entityToRemove);
        setSelectedEntities(updatedEntities);
        handleInputChange("selectedEntities", updatedEntities);
    };

    const handleYearSelect = (selectedYear) => {
        if (!selectedYears.includes(selectedYear)) {
            const updatedYears = [...selectedYears, selectedYear];
            setSelectedYears(updatedYears);
            handleInputChange("selectedYears", updatedYears);
        }
    };

    const onRemoveYear = (yearToRemove) => {
        const updatedYears = selectedYears.filter((year) => year !== yearToRemove);
        setSelectedYears(updatedYears);
        handleInputChange("selectedYears", updatedYears);
    };

    const handleDescriptionChange = (e) => {
        const value = e.target.value;
        setDescription(value);
        handleInputChange("description", value);
    };

    const handleDateChange = (field, value) => {
        const newDate = new Date(value);
        if (field === "fromDate") {
            setFromDate(newDate);
            handleInputChange("fromDate", newDate);
        } else {
            setToDate(newDate);
            handleInputChange("toDate", newDate);
        }
    };

    const incorporationYear = useMemo(() => selectedEntity ?
        new Date(entities.find((entity) => entity.entityId === entityOptions[selectedEntity])?.incorporationDate).getFullYear()
        : null, [entities, entityOptions, selectedEntity]);

    const renderEntityDropdown = () => (
        <div className={styles.entity_container}>
            <EditInputItem
                name="entity" type="dropdown" labelTitle="Entity"
                options={Object.keys(entityOptions)}
                value={selectedEntity}
                onSelect={handleEntityChange}
                onChange={() => {
                    setEntityType(type);
                    setShowEntityError(false);
                }}
                showError={showErrors && errors.selectedEntity}
                errorMessage={errors.selectedEntity}
            />
        </div>
    );

    const renderDescriptionField = () => {
        let descriptionLabel = "Description";

        if (title === LBL__FINANCIAL_MODELLING) {
            descriptionLabel = "Description of Parameters";
        } else if (title === LBL__BOARD_RESOLUTION) {
            descriptionLabel = "Description of Resolution";
        } else if (title === LBL__COI_AMENDAMENT) {
            descriptionLabel === "Description of Change"
        }

        return (
            <div className={styles.textarea_container}>
                <p className={styles.textarea_label}>{descriptionLabel}</p>
                <EditInputItem
                    type="textarea" name="description" value={description}
                    onChange={handleDescriptionChange}
                    className={styles.text_area_style}
                    showError={showErrors && errors.description}
                    errorMessage={errors.description}
                />
            </div>
        );
    };

    const renderDatePickers = () => (
        <div className={styles.from_to_cont}>
            <div className={styles.from_date}>
                <EditInputItem
                    name="From" type="datepicker" labelTitle="From" value={fromDate}
                    onChange={(value) => handleDateChange("fromDate", value)}
                    showError={showErrors && errors.fromDate}
                    errorMessage={errors.fromDate}

                />
            </div>
            <div className={styles.from_date}>
                <EditInputItem
                    name="To" type="datepicker" labelTitle="To" value={toDate}
                    onChange={(value) => handleDateChange("toDate", value)}
                    showError={showErrors && errors.toDate}
                    errorMessage={errors.toDate}

                />
            </div>
        </div>
    );

    const renderTaxResidencyCertificate = () => (
        <div className={styles.trc_container}>
            <div className={styles.tax_first_part}>
                {renderEntityDropdown()}
                {/* {title !== LBL__TAX_RESIDENCY_CERTIFICATE && renderEntityDropdown()} */}
                <EditInputItem
                    name="years" type="dropdown" labelTitle="TRC required for Year/s" placeholder="Select & Add a year"
                    options={incorporationYear
                        ? Array.from({ length: currentYear - incorporationYear + 1 }, (_, i) => incorporationYear + i)
                            .reverse()
                        : []
                    }
                    onChange={handleYearSelect}
                    disabled={!selectedEntity}
                />
            </div>

            <div className={styles.second_part_tax}>
                {selectedYears.map((year) => (
                    <p className={styles.each_year} key={year}>
                        {year}{" "}
                        <img src={CloseWhite} alt="Remove" onClick={() => onRemoveYear(year)} />
                    </p>
                ))}
            </div>
        </div>
    );

    const renderGroupConsolidation = () => {
        const options = Object.keys(entityOptions);
        if (options.length === 0) {
            return <p>No entities available.</p>;
        }
        return (
            <div className={styles.group_selected_entites_container}>
                {selectedEntities.map((entity) => (
                    <p key={entity} className={styles.selected_entity}>
                        {entity}{" "}
                        <img src={CloseWhite} alt="Remove" onClick={() => onRemoveEntity(entity)} />
                    </p>
                ))}
            </div>
        );
    };

    const titleMapping = {
        [LBL__COI_AMENDAMENT]: [renderDescriptionField],
        [LBL__BOARD_RESOLUTION]: [renderDescriptionField],
        [LBL__FINANCIAL_MODELLING]: [renderDescriptionField],
        [LBL__FINANCIAL_AUDIT]: [renderDatePickers],
        [LBL__GAAP_FINANCIALS]: [renderDatePickers],
        [LBL__GROUP_CONSOLIDATION]: [renderGroupConsolidation, renderDatePickers],
        [LBL__TAX_RESIDENCY_CERTIFICATE]: [renderTaxResidencyCertificate],
        [LBL__EFTPS_REGISTRATION]: [],
    };

    const content = (
        <>
            {title !== LBL__TAX_RESIDENCY_CERTIFICATE && renderEntityDropdown()}
            {titleMapping[title]?.map((renderField, index) => <div key={index}>{renderField()}</div>)}
        </>
    );

    // const content = (
    //     <>
    //         {renderEntityDropdown()}
    //         {titleMapping[title]?.map((renderField, index) => <div key={index}>{renderField()}</div>)}
    //     </>
    // );

    return <div className={styles.container}>{content}</div>;
};

export default ServiceForm;



