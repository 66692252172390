import React from 'react';
import styles from './ServiceCardWrapper.module.css';

const ServiceCardWrapper = ({ header, footer, className, showSeperator, onClick }) => {
    return (
        <div className={`${styles.container} ${className}`} onClick={onClick} >
            {header}
            {showSeperator && <div className={styles.separator}></div>}
            {footer}
        </div>
    );
};

export default ServiceCardWrapper;