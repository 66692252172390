import { useState, useRef } from "react";
import { useSelector } from "react-redux";
import styles from "./CartEntityDropDown.module.css";
import { useOutsideClick } from "../../../hooks/use-outsideClick";
import { motion } from "framer-motion";
import arrow from "/assets/common/ArrowDown_Filled.svg";

const CartEntityDropDown = ({ onChange }) => {
  const dropdownRef = useRef(null);

  const { cartPendingList, selectedEntity } = useSelector(
    (state) => state.cart
  );
  const { entities } = useSelector((state) => state.entity);

  const [isOpen, setIsOpen] = useState(false);

  const arrowVariants = { open: { rotate: -180 }, close: { rotate: 0 } };

  useOutsideClick({
    ref: dropdownRef,
    handler: () => {
      setIsOpen(false);
    },
  });

  const handleEntityChange = (entity) => {
    onChange(entity);
    setIsOpen(false);
  };

  return (
    <div className={styles.container} ref={dropdownRef}>
      <div className={styles.header} onClick={() => setIsOpen(!isOpen)}>
        {selectedEntity?.entityName || ""}
        <motion.img
          key={`arrow-${isOpen ? "up" : "down"}`}
          variants={arrowVariants}
          initial={isOpen ? "close" : "open"}
          animate={isOpen ? "open" : "close"}
          transition={{ duration: 1, type: "spring" }}
          src={arrow}
          alt=""
          className={styles.arrow}
        />
      </div>
      {isOpen && (
        <div className={styles.dropdown_list_container}>
          <ul className={styles.dropdown_list}>
            {entities?.map((entity) => (
              <li
                key={entity.entityId}
                onClick={() => handleEntityChange(entity)}
                className={styles.dropdown_list_item}
              >
                {cartPendingList?.includes(entity.entityId) ? (
                  <div className={styles.pending_items}></div>
                ) : null}
                {entity.entityName}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default CartEntityDropDown;
