import { useState } from "react";
import styles from "./SubscriptionsPage.module.css";
import TabListItem from "../../UI/Tab/TabListItem";
import TabContent from "../../UI/Tab/TabContent";
import Wrapper from "../../UI/Wrapper/Wrapper";
import SearchBar from "../../UI/Input/SearchBar";
import FixedServices from "./FixedServices.jsx";
import GetServicesListPage from "./GetServicesListPage.jsx";
import { tabStyles } from "../../../consts/Constants";
import { subscriptionTabs } from "../../../consts/TextConstants.jsx";
import TaxComplianceListPage from "./TaxComplianceListPage.jsx";

const SubscriptionsPage = () => {
  const [activeTab, setActiveTab] = useState(
    subscriptionTabs.COMPLIANCE
  );
  const [searchValue, setSearchValue] = useState("");

  // Function to handle tab change events
  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  // Function to handle changes in the search bar input
  const handleSearchChange = (value) => {
    setSearchValue(value);
  };

  return (
    <Wrapper title="Shop">
      <div className={styles.container}>
        <div className={styles.tabs__list}>
          {/* Tax Compliance tab */}
          <TabListItem
            onClick={() =>
              handleTabChange(subscriptionTabs.COMPLIANCE)
            }
            activeTab={activeTab === subscriptionTabs.COMPLIANCE}
            tabStyle={tabStyles.UNDERLINED}
          >
            {subscriptionTabs.COMPLIANCE}
          </TabListItem>
          {/* Services tab */}
          {/* <TabListItem
                        onClick={() => handleTabChange(subscriptionTabs.SERVICES)}
                        activeTab={activeTab === subscriptionTabs.SERVICES}
                        tabStyle={tabStyles.UNDERLINED}
                    >
                        {subscriptionTabs.SERVICES}
                    </TabListItem> */}
        </div>

        {/* Search Bar */}
        <SearchBar
          id="input-search"
          placeholder="Search Services..."
          onChange={handleSearchChange}
          inputstyle={styles.search_bar}
        />

        {/* Content area for tabs */}
        <div className={styles.tab__content}>
          {/* Content for the "Tax Compliance" tab */}
          <TabContent
            id={subscriptionTabs.COMPLIANCE}
            activeTab={activeTab}
          >
            <TaxComplianceListPage
              activeTab={activeTab}
              searchValue={searchValue}
            />
          </TabContent>

          {/* Content for the "Services" tab */}
          <TabContent
            id={subscriptionTabs.SERVICES}
            activeTab={activeTab}
          >
            {/* <div className={styles.tab__section}>
              <FixedServices />
              <GetServicesListPage searchValue={searchValue} />
            </div> */}
          </TabContent>
        </div>
      </div>
    </Wrapper>
  );
};

export default SubscriptionsPage;
