import { useMemo } from "react";
import { useSelector } from "react-redux";
import { useGetAllComplianceListQuery } from "./apis/subscriptionsApi-slice";
import ServicesHomeCard from "./ui-subscriptions/ServicesHomeCard";
import styles from "./TaxComplianceListPage.module.css";
import { DEFAULT_PAGE_SIZE_MAX } from "../../../consts/AppConstants";
const TaxComplianceListPage = ({ activeTab, searchValue }) => {
  const { complianceList } = useSelector((state) => state.subscriptions);

  // Fetching services data using a custom hook (or using passed `complianceData` prop)
  const { isLoading } = useGetAllComplianceListQuery({page_size: DEFAULT_PAGE_SIZE_MAX});

  // Filter the compliances based on the search value dynamically
  const filteredCompliances = useMemo(
    () =>
      complianceList.filter(
        (compliance) =>
          compliance.complianceName?.toLowerCase()
            .includes(searchValue?.toLowerCase()) ||
          compliance.complianceForms?.toLowerCase()
            .includes(searchValue?.toLowerCase())
      ),
    [searchValue, complianceList]
  );

  // Render the filtered compliances dynamically
  return (
    <div className={styles.card}>
      {filteredCompliances && filteredCompliances.length > 0 ? (
        filteredCompliances.map((compliance, index) => (
          <ServicesHomeCard
            key={`${compliance.complianceId}-${index}`}
            title={compliance.complianceName}
            shortDescription={compliance.shortDescription}
            price={compliance.compliancePrice}
            activeTab={activeTab}
            complianceId={compliance.complianceId}
          />
        ))
      ) : (
        <p>No services available</p>
      )}
    </div>
  );
};

export default TaxComplianceListPage;
