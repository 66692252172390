import { useState, useRef } from "react";
import { useSelector } from "react-redux";
import styles from "./TopNavBar.module.css";
import AccountDropDown from "./AccountDropDown";
import Button from "../../UI/Button/Button";
import personBlack from "/assets/topbar/Person_Black.svg";
import notificationIcon from "/assets/topbar/NotificationBell.svg";
import AllNotifications from "./AllNotifications";
import { useOutsideClick } from "../../../hooks/use-outsideClick";

import cartIcon from "/assets/topbar/Cart_Icon.svg";
import CartPage from "../../Main/Cart/CartPage";
import { useGetAvailableCartEntityListQuery } from "../../Main/Cart/apis/cartApi-slice";
const TopNavBar = ({ ...props }) => {
  const [showAccountDropDown, setShowAccountDropDown] = useState(false);
  const [hasNewNotification, setHasNewNotification] = useState(true);
  const [showAllNotifications, setShowAllNotifications] = useState(false);
  const [showCartOverlay, setShowCartOverlay] = useState(false);

  const accountRef = useRef(null);
  const notificationRef = useRef(null);

  const { cartPendingList } = useSelector((state) => state.cart);

  const { isLoading: isLoadingCartEntityList } = useGetAvailableCartEntityListQuery();

  useOutsideClick({
    ref: accountRef,
    handler: () => setShowAccountDropDown(false),
  });

  useOutsideClick({
    ref: notificationRef,
    handler: () => setShowAllNotifications(false),
  });

  const handleAccountClick = () => {
    setShowAccountDropDown(!showAccountDropDown);
  };
  const handleCartClick = () => {
    setShowCartOverlay(true);
  };

  const handleNotificationClick = () => {
    // setShowAllNotifications(!showAllNotifications);
    // setHasNewNotification(false);
  };

  return (
    <div className={styles.topbar}>
      {/* cart container */}
      <div>
        <Button className={styles.cart_action} onClick={handleCartClick}>
          <img src={cartIcon} alt="" />
          {cartPendingList?.length > 0 && (
            <p className={styles.pending_cart_circle}></p>
          )}
          <p className={styles.label}>CART</p>
        </Button>
        {showCartOverlay && (
          <CartPage onClose={() => setShowCartOverlay(false)} />
        )}
      </div>

      {/*  */}
      <div ref={notificationRef}>
        <Button
          className={styles.notification_action}
          onClick={handleNotificationClick}
          disabled={true}
        >
          <img src={notificationIcon} alt="Notification Bell" />
          {hasNewNotification && <p className={styles.notification_circle}></p>}
        </Button>

        {showAllNotifications && (
          <div className={styles.all_notification_container}>
            <AllNotifications />
          </div>
        )}
      </div>

      <div ref={accountRef}>
        <Button className={styles.account_action} onClick={handleAccountClick}>
          <img src={personBlack} alt="" />
          {/* <p className={styles.label}>{userDetails?.userName}</p>
          <img src={dropdownProfile} alt="" /> */}
        </Button>
        {showAccountDropDown && <AccountDropDown onLogout={props.onLogout} />}
      </div>
    </div>
  );
};

export default TopNavBar;
