import styles from "./ListCartItems.module.css";
import {
  CustomTable,
  TableRow,
  TableHeader,
  TableData,
} from "../../UI/Table/CustomTable";
import Button from "../../UI/Button/Button";
import cancelIcon from "/assets/topbar/Cancel.svg";
import emptyCart from "/assets/topbar/Empty_Cart.svg";
import { useSelector, useDispatch } from "react-redux";
import { cartActions } from "./reducers/cart-slice";
import {
  LBL__CART_IS_EMPTY,
  TBL__ALL_CART,
} from "../../../consts/TextConstants";
import {
  useRemoveCartItemMutation,
  useLazyGetCartDataQuery,
  useLazyGetAvailableCartEntityListQuery
} from "./apis/cartApi-slice";

const ListCartItems = () => {
  const { cartItems, selectedEntity } = useSelector((state) => state.cart);
  
  const [removeCartItem, { isLoading: loadingRemoveCartItem }] =
    useRemoveCartItemMutation();
  const [getCartData, { isLoading: loadingGetCartData }] =
    useLazyGetCartDataQuery();
  const [getAvailableCartEntityList, { isLoading: loadingGetCartEntity }] =
    useLazyGetAvailableCartEntityListQuery();

  const handleDelete = async (item) => {
    try {
      await removeCartItem({ cart_id: item.cartId }).unwrap();
      await getCartData({ entity_id: selectedEntity?.entityId }).unwrap();
      await getAvailableCartEntityList().unwrap();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className={styles.container}>
      <CustomTable className={styles.table}>
        <thead>
          <TableRow className={styles.header}>
            {/* Dynamically render the table headers */}
            {Object.keys(TBL__ALL_CART).map((key, col) => (
              <TableHeader key={`header-${col}`}>
                {TBL__ALL_CART[key]}
              </TableHeader>
            ))}
            <TableHeader></TableHeader>
            {/* Empty column for the delete button */}
          </TableRow>
        </thead>
        <tbody>
          {cartItems.length > 0 ? (
            cartItems.map((item, rowIndex) => (
              <TableRow key={`table-row-${rowIndex}`}>
                {Object.keys(TBL__ALL_CART).map((key, col) => (
                  <TableData key={`table-data-${col}-${rowIndex}`}>
                    {/* Render content for "items" */}
                    {key === "items" && (
                      <div className={styles.header_title_label}>
                        {item.productName}
                        <div className={styles.entity_container}>
                          <p className={styles.short_description_label}>
                            {item.description}
                          </p>
                          <p className={styles.entity_label}>
                            {selectedEntity?.entity_name}
                          </p>
                        </div>
                      </div>
                    )}
                    {/* Render content for "year" */}
                    {key === "year" && <p>{item.year || "-"}</p>}
                    {/* Render content for "price" */}
                    {key === "price" && <p>{`$${item.price}`}</p>}
                  </TableData>
                ))}
                <TableData>
                  <Button
                    onClick={() => handleDelete(item)}
                    className={styles.btn_cancel}
                  >
                    <img src={cancelIcon} alt="" />
                  </Button>
                </TableData>
              </TableRow>
            ))
          ) : (
            <TableRow className={styles.empty_cart_show}>
              <TableData className={styles.title}>
                <p className={styles.empty_box}></p>
                <div className={styles.animation}>
                  <p className={styles.animation_one}></p>
                  <p className={styles.animation_one}></p>
                </div>
              </TableData>
              <TableData className={styles.year_label}>
                <p className={styles.animation_one}></p>
              </TableData>
              <TableData className={styles.price_label}>
                <p className={styles.animation_one}></p>
              </TableData>
              <TableData></TableData>
            </TableRow>
          )}
        </tbody>
      </CustomTable>

      {/* Show "Cart is Empty" message when the cart is empty */}
      {cartItems.length === 0 && (
        <div className={styles.cart_empty}>
          <img src={emptyCart} alt="" />
          <p className={styles.empty_cart_label}>{LBL__CART_IS_EMPTY}</p>
        </div>
      )}
    </div>
  );
};

export default ListCartItems;
