import styles from "./DescriptionForm.module.css";

const DescriptionForm = ({ complianceDetailedDescription }) => {
  return (
    <div className={styles.data_container}>
      {complianceDetailedDescription?.map((section, i) => (
        <div key={`${i}-${section.header}`} className={styles.content_block}>
          {/* header , make text uppercase */}
          {section.header && (
            <h2 className={styles.question_text}>
              {section.header.toUpperCase()}
            </h2>
          )}

          {/*  paragraph */}
          {section.para && <p className={styles.para}>{section.para}</p>}

          {/*bullet points */}
          {section.bullet && section.bullet.length > 0 && (
            <ul className={styles.ul_list}>
              {section.bullet.map((item, j) => (
                <li key={`${i}-${j}`} className={styles.list_item}>
                  {item}
                </li>
              ))}
            </ul>
          )}
        </div>
      ))}
    </div>
  );
};

export default DescriptionForm;
