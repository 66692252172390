
//this is w 100%
import OverlayRegular from "../../UI/Modal/OverlayRegular";
import CartBody from "./CartBody";
import CartHeader from "./CartHeader";
import styles from "./CartPage.module.css";


const CartPage = ({ onClose }) => {

    return (
        <OverlayRegular onClose={onClose}>
            <div className={styles.container}>
                <CartHeader />
                <CartBody />
            </div>
        </OverlayRegular>
    );
};

export default CartPage;
