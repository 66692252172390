import { useState, useMemo, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import styles from "./TaxPeriodForm.module.css";
import Dropdown from "../../../../UI/Dropdown/Dropdown";
import editIcon from "/assets/common/EditPen_Blue.svg";
import Button from "../../../../UI/Button/Button";
import {
  complianceEditForms,
  MONTH_NAMES_SHORT,
} from "../../../../../consts/Constants";
import EditOverlay from "./EditForms/EditOverlay";
import { convertISODateToStr } from "../../../../../utils/common-utils";
import { complianceActions } from "../../reducers/compliance-slice";

const OptionField = ({ children, label, showError, errorMessage }) => {
  return (
    <div className={styles.option_field}>
      <div className={styles.option_label}>{label}</div>
      {children}
      {showError && <div className={styles.error_message}>{errorMessage}</div>}
    </div>
  );
};

const EditButton = ({ ...props }) => {
  return (
    <Button className={styles.btn__edit} {...props}>
      <img src={editIcon} alt="edit" />
    </Button>
  );
};

const TaxPeriodForm = ({ showError }) => {
  const { taxPeriod, selectedEntity } = useSelector(
    (state) => state.compliance
  );
  const [editOverlay, setEditOverlay] = useState({ show: false, formName: "" });
  const dispatch = useDispatch();

  const { fromDate, toDate } = useMemo(() => {
    const defaultPeriod = taxPeriod?.period;
    const fromYear = taxPeriod?.year;
    if (!defaultPeriod?.from || !defaultPeriod?.to) {
      const incorporationDate = new Date(selectedEntity?.incorporationDate);
      return {
        fromDate:
          fromYear == incorporationDate.getFullYear()
            ? incorporationDate
            : null,
        toDate: null,
      };
    }
    const toMonth = MONTH_NAMES_SHORT.indexOf(defaultPeriod?.to?.slice(-3));
    const fromMonth = MONTH_NAMES_SHORT.indexOf(defaultPeriod?.from?.slice(-3));
    const fromDay = defaultPeriod?.from?.slice(0, -3);

    const toYear =
      toMonth < fromMonth ? Number(fromYear) + 1 : Number(fromYear);

    const toDate = new Date(toYear, toMonth + 1, 0);
    const fromDate = new Date(fromYear, fromMonth, fromDay ? fromDay : 1);

    return { fromDate, toDate };
  }, [taxPeriod]);

  useEffect(() => {
    dispatch(complianceActions.setComplianceDate({ fromDate, toDate }));
  }, [fromDate, toDate]);

  const handleEdit = (formName) => {
    setEditOverlay({
      show: true,
      formName: formName,
    });
  };

  const handleCloseEditOverlay = () => {
    setEditOverlay({ show: false, formName: "" });
  };

  return (
    <div className={styles.container}>
      <div className={styles.title}>Confirm Tax Period</div>
      <div className={styles.form}>
        <div className={styles.period_wrapper}>
          <OptionField label="Year">
            <div className={styles.option}>{taxPeriod.year}</div>
          </OptionField>

          <OptionField
            label="Filing Period"
            showError={showError && !fromDate}
            errorMessage="Please select a valid filing period"
          >
            <div className={styles.options_wrapper}>
              <div className={styles.option}>
                {fromDate
                  ? `${fromDate.toLocaleDateString("en-US", {
                      month: "short",
                      day: "2-digit",
                    })} ${fromDate.getFullYear()}`
                  : ""}
              </div>
              <span className={styles.separator}></span>
              <div className={styles.option}>
                {toDate
                  ? `${toDate.toLocaleDateString("en-US", {
                      month: "short",
                      day: "2-digit",
                    })} ${toDate.getFullYear()}`
                  : ""}
              </div>
              <EditButton
                onClick={() => handleEdit(complianceEditForms.TAX_PERIOD)}
              />
            </div>
          </OptionField>
          <OptionField
            label="IRS Tax classification"
            showError={showError && !taxPeriod.taxClassification}
            errorMessage="Please select a valid IRS tax classification"
          >
            <div
              className={`${styles.options_wrapper} ${styles.tax_classification}`}
            >
              <div className={styles.option}>{taxPeriod.taxClassification}</div>
              <EditButton
                onClick={() =>
                  handleEdit(complianceEditForms.IRS_CLASSIFICATION)
                }
              />
            </div>
          </OptionField>
        </div>

        <div className={styles.shares_wrapper}>
          <OptionField
            label="Authorized Shares"
            showError={showError && !taxPeriod.authorizedShares}
            errorMessage="Please enter the valid authorized shares"
          >
            <div
              className={`${styles.options_wrapper} ${styles.issued_shares}`}
            >
              <div className={styles.option}>{taxPeriod.authorizedShares}</div>
              <EditButton
                onClick={() =>
                  handleEdit(complianceEditForms.AUTHORIZED_SHARES)
                }
              />
            </div>
          </OptionField>
          <OptionField
            label={`Total Issued Shares * ${
              toDate ? "as on " + convertISODateToStr(toDate) : ""
            }`}
            showError={showError && !taxPeriod.issuedShares}
            errorMessage="Please enter the valid issued shares"
          >
            <div
              className={`${styles.options_wrapper} ${styles.issued_shares}`}
            >
              <div className={styles.option}>{taxPeriod.issuedShares}</div>
              <EditButton
                onClick={() => handleEdit(complianceEditForms.ISSUED_SHARES)}
                disabled={!taxPeriod.authorizedShares}
              />
            </div>
          </OptionField>
        </div>
      </div>

      {editOverlay.show && (
        <EditOverlay
          onClose={handleCloseEditOverlay}
          formName={editOverlay.formName}
          className={
            editOverlay.formName === complianceEditForms.TAX_PERIOD
              ? styles.edit_overlay_tax_period
              : styles.edit_overlay_irs
          }
          entityType={taxPeriod.entityType}
          fromYear={taxPeriod.year}
          entityId={selectedEntity?.entityId}
          // defaultPeriod={taxPeriod?.period}
          defaultFromDate={
            fromDate ? fromDate : new Date(`Jan 1, ${taxPeriod.year}`)
          }
          defaultToDate={
            toDate ? toDate : new Date(`Dec 31, ${taxPeriod.year}`)
          }
          incorporationDate={new Date(selectedEntity?.incorporationDate)}
          defaultTaxClassification={taxPeriod.taxClassification}
          defaultIssuedShares={taxPeriod.issuedShares}
          defaultAuthorizedShares={taxPeriod.authorizedShares}
          toDate={toDate}
        />
      )}
    </div>
  );
};

export default TaxPeriodForm;
