import OverlayRegular from "../../../UI/Modal/OverlayRegular";
import GetQuoteAnimation from "/assets/common/Get_Quote_Animation.svg";
import PersonIcon from "/assets/common/Person_Icon.svg";
import GetQuoteLinesIcon from "/assets/common/Get_Quote_Lines_Icon.svg";
import styles from "./GetQuoteOverlay.module.css";

const GetQuoteOverlay = ({ onClose }) => {
    return (
        <OverlayRegular onClose={onClose}>
            <div className={styles.container}>
                <div className={styles.three_icons_cont}>
                    <img src={PersonIcon} alt="" />
                    <img src={GetQuoteLinesIcon} alt="" />
                    <img src={GetQuoteAnimation} alt="" />
                </div>

                <div className={styles.para}>
                    <p>Thanks for showing interest,</p>
                    <p>
                        we will get back to you with the
                        <span className={styles.blue_text}> Best</span> quote.
                    </p>
                </div>

            </div>
        </OverlayRegular>
    );
};

export default GetQuoteOverlay;