import { useState, useMemo } from "react";
import EditInputItem from "../../Edit/EditInputItem";
import styles from "./EditFilingPeriod.module.css";
import OverlayRegular from "../../../UI/Modal/OverlayRegular";
import Button from "../../../UI/Button/Button";

const EditFilingPeriod = ({
  defaultFromDate,
  defaultToDate,
  incorporationDate,
  taxYear,
  onClose,
  onSubmit,
}) => {
  const [fromDate, setFromDate] = useState(new Date(defaultFromDate));
  const [toDate, setToDate] = useState(new Date(defaultToDate));
  const incorporationYear = useMemo(
    () => incorporationDate?.getFullYear(),
    [incorporationDate]
  );

  const handleSubmit = () => {
    onSubmit({ fromDate, toDate });
  };

  return (
    <OverlayRegular onClose={onClose}>
      <div className={styles.container}>
        <div className={styles.header}>
          <div className={styles.title}>Tax Period</div>
          <div className={styles.sub_heading}>Change tax filing period</div>
        </div>

        <div className={styles.body_cont}>
          {/* Use the new EditInputFields component here */}
          <div className={styles.form_inputs_cont}>
            <EditInputItem
              name="fromDate"
              type="datepicker"
              labelTitle="FROM"
              onChange={(value) => setFromDate(value)}
              value={fromDate}
              startDate={
                incorporationYear == taxYear
                  ? new Date(
                      Math.max(
                        new Date(
                          toDate.getFullYear(),
                          toDate.getMonth() - 11,
                          1
                        ),
                        incorporationDate
                      )
                    )
                  : new Date(
                      Math.max(
                        new Date(
                          toDate.getFullYear(),
                          toDate.getMonth() - 11,
                          1
                        ),
                        new Date(`Jan 01, ${taxYear}`)
                      )
                    )
              }
              endDate={
                new Date(
                  Math.min(
                    toDate.getTime() - 2 * 24 * 60 * 60 * 1000,
                    new Date(`Dec 31, ${taxYear}`).getTime()
                  )
                )
              }
            />

            <EditInputItem
              name="toDate"
              type="datepicker"
              labelTitle="TO"
              onChange={(value) => setToDate(value)}
              value={toDate}
              startDate={new Date(fromDate.getTime() + 2 * 24 * 60 * 60 * 1000)}
              endDate={
                new Date(fromDate.getFullYear(), fromDate.getMonth() + 12, 0)
              }
            />
          </div>
          <div className={styles.actions}>
            <Button
              className={styles.btn__submit}
              buttonstyle="default-blue"
              onClick={handleSubmit}
            >
              Submit
            </Button>
          </div>
        </div>
      </div>
    </OverlayRegular>
  );
};

export default EditFilingPeriod;
