import { useState } from "react";
import {
  CustomTable,
  TableData,
  TableRow,
  TableHeader,
} from "../../UI/Table/CustomTable";
import styles from "./AddedServicesList.module.css";
import { useSelector } from "react-redux";
import Payments from "./PaymentsFolder/Payments";
import EmailCard from "../../UI/Email/EmailCard";
import ServicesDocumentOverlay from "./ServicesDocumentsOverlay";
import ServiceTableRow from "./ServiceTableRow";
import { TBL__SERVICES } from "../../../consts/TextConstants";
import { useAddServicesMutation } from "./apis/serviceApi-slice";
import {
  URL__STRIPE_SUCCESS_REDIRECT,
  URL__STRIPE_FAILURE_REDIRECT,
} from "../../../consts/APIConstants";
import { setStripeRedirectUrls, removeStripeRedirectUrls } from "../../../utils/authentication";
import { absolutePaths } from "../../../consts/PathConstants";

const AddedServicesList = ({ onSuccess, entityName }) => {
  // const { primaryEntity } = useSelector((state) => state.entity);
  const { servicesAdded } = useSelector((state) => state.services);

  const [showPayments, setShowPayments] = useState(false);
  const [selectedService, setSelectedService] = useState(null);
  const [showEmail, setShowEmail] = useState(false);
  const [showServicesDocumentOverlay, setShowServicesDocumentOverlay] =
    useState(false);

  const [addService, { isLoading }] = useAddServicesMutation();

  const handleEmail = (service) => {
    setSelectedService(service);
    setShowEmail(true);
  };

  const handlePayClick = async (service) => {
    // setSelectedService(service);
    // setShowPayments(true);
    try {
      const postData = {
        subscription_inputs: {},
        entity_id: service?.entityId,
        product_id: service.serviceId,
        subscription_id: service.subscriptionId,
        success_url: URL__STRIPE_SUCCESS_REDIRECT,
        cancel_url: URL__STRIPE_FAILURE_REDIRECT,
      };
      setStripeRedirectUrls(absolutePaths.SERVICES, absolutePaths.SERVICES);
      const response = await addService(postData).unwrap();

      const reditectUrl = response?.url;
      window.location.href = reditectUrl;
    } catch (error) {
      console.error("Failed to add service:", error);
      removeStripeRedirectUrls();
    }
  };

  const handleOverlayClose = () => {
    setShowEmail(false);
  };

  const handleServicesDocumentOverlay = (service) => {
    setSelectedService(service);
    setShowServicesDocumentOverlay(true);
  };

  const handlePaymentSuccess = () => {
    setShowPayments(false);
    onSuccess();
  };

  return (
    <div className={styles.table_container}>
      <CustomTable className={styles.table}>
        <thead>
          <TableRow>
            {Object.values(TBL__SERVICES).map((header, index) => (
              <TableHeader key={`service-header-${index}`}>
                {header}
              </TableHeader>
            ))}
          </TableRow>
        </thead>
        <tbody>
          {servicesAdded.length > 0 ? (
            servicesAdded.map((serviceItem, row) => (
              <ServiceTableRow
                key={`service-row-${row}`}
                serviceItem={serviceItem}
                row={row}
                handleEmail={handleEmail}
                handlePayClick={handlePayClick}
                handleDocumentsClick={handleServicesDocumentOverlay}
                onSuccess={onSuccess}
              />
            ))
          ) : (
            <TableRow key="no-data">
              <TableData colSpan={Object.values(TBL__SERVICES).length}>
                <div>No services found</div>
              </TableData>
            </TableRow>
          )}
        </tbody>
      </CustomTable>

      {showPayments && selectedService && (
        <Payments
          onClose={() => setShowPayments(false)}
          onSuccess={handlePaymentSuccess}
          selectedService={selectedService}
          subscriptionId={selectedService.subscriptionId}
        />
      )}
      {showEmail && (
        <EmailCard
          onClose={handleOverlayClose}
          serviceItem={selectedService}
          entityId={selectedService?.entityId}
          subscriptionId={selectedService.subscriptionId}
          taskId={selectedService.taskId}
          disableSubject={true}
          initiatedFromTask={true}
          defaultSubject={`${selectedService.ticketNumber} | ${selectedService.serviceName} | ${selectedService.entityName}`}
        />
      )}
      {showServicesDocumentOverlay && (
        <ServicesDocumentOverlay
          onClose={() => setShowServicesDocumentOverlay(false)}
          serviceItem={selectedService}
        />
      )}
    </div>
  );
};

export default AddedServicesList;
