import { useEffect, useState, useRef } from "react";
import { motion } from "framer-motion";
import styles from "./DatePicker.module.css";
import CalendarView from "./CalendarView";
import arrowDown from "/assets/common/ArrowDown_Filled.svg";
import { splitDate } from "../../../utils/common-utils";

const DatePicker = ({
  selectedDate,
  startDate = new Date("01/01/1800"),
  endDate = new Date(),
  ...props
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const ref = useRef(null);
  const arrowVariants = { open: { rotate: -180 }, close: { rotate: 0 } };

  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  const handleDateChange = (newDate) => {
    props.onChange(newDate);
  };

  useEffect(() => {
    const checkIfClickedOutside = (event) => {
      if (isOpen && ref.current && !ref.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);

    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [isOpen]);

  // const { month, day, year } = splitDate(selectedDate);
  const month = selectedDate?.getMonth() + 1 ;
  const day = selectedDate?.getDate();
  const year = selectedDate?.getFullYear();

  return (
    <div className={styles.container} ref={ref}>
      <div
        className={`${styles.input_container} ${props.className}`}
        onClick={handleClick}
      >
        <div className={styles.date_value}>{ month && day && year ? `${day}-${month}-${year}` : ""}</div>
        <motion.img
          key={`arrow-${isOpen ? "up" : "down"}`}
          variants={arrowVariants}
          initial={isOpen ? "close" : "open"}
          animate={isOpen ? "open" : "close"}
          transition={{ duration: 1, type: "spring" }}
          src={arrowDown}
          alt=""
        />
      </div>
      {isOpen && (
        <CalendarView
          selectedDate={selectedDate || new Date()}
          onChange={handleDateChange}
          className={styles.dropdown_style}
          startDate={startDate}
          endDate={endDate}
          onSelectDay={() => setIsOpen(false)}
        />
      )}
    </div>
  );
};

export default DatePicker;
