import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import styles from "./OnboardingCollectIncorporationDetails.module.css";
import TreeViewItem from "./TreeView/TreeViewItem";
import Dropdown from "../UI/Dropdown/Dropdown";
import DatePicker from "../UI/DatePicker/DatePicker";
import AutoComplete from "../UI/AutoComplete";
import InputRadio from "../UI/Input/InputRadio";
import {
  LBL__INCORPORATION_STATE,
  LBL__INCORPORATION_DATE,
  LBL__ENTITY_TYPE,
  LBL__ENTITY_IN_OTHER_STATE,
  LBL__YES,
  LBL__NO,
  entityTypes,
} from "../../consts/TextConstants";
import { getFilteredUSStatesList } from "../../utils/listUSStates";
import { convertToISODate } from "../../utils/common-utils";

const OnboardingCollectIncorporationDetails = ({ ...props }) => {
  const { collectedData } = useSelector((state) => state.onboard);
  const [incorporationState, setIncorporationState] = useState(
    collectedData?.incorporationState || ""
  );
  const [entityType, setEntityType] = useState(
    collectedData?.entityType || entityTypes.US[0]
  );
  const [incorporationDate, setIncorporationDate] = useState(
    collectedData?.incorporationDate
      ? new Date(collectedData?.incorporationDate)
      : null
  );
  // const [otherStateValue, setOtherStateValue] = useState(
  //   collectedData?.otherStateValue || LBL__NO
  // );

  useEffect(() => {
    props.onChange(
      {
        entityType,
        incorporationState,
        incorporationDate: convertToISODate(incorporationDate),
        // otherStateValue,
      },
      entityType && incorporationState && incorporationDate
    );
  }, [entityType, incorporationState, incorporationDate]);

  const handleSelectIncorporationState = (selectedState) => {
    setIncorporationState(selectedState.key);
  };

  const handleEntityTypeChange = (value) => {
    setEntityType(value);
  };

  const handleIncorporationDateChange = (value) => {
    setIncorporationDate(value);
  };

  // const handleOtherStateSelect = (value) => {
  //   setOtherStateValue(value);
  // };

  return (
    <>
      <TreeViewItem labelTitle={LBL__INCORPORATION_STATE}>
        <AutoComplete
          defaultValue={collectedData?.incorporationState || ""}
          customFilter={true}
          filteredListFunc={getFilteredUSStatesList}
          id="incorporation_state"
          label={LBL__INCORPORATION_STATE}
          onSelect={handleSelectIncorporationState}
          autocompleteSize={styles.autocomplete}
        />
      </TreeViewItem>

      <TreeViewItem labelTitle={LBL__INCORPORATION_DATE}>
        <DatePicker
          selectedDate={incorporationDate}
          onChange={handleIncorporationDateChange}
          className={styles.datepicker}
        />
      </TreeViewItem>

      <TreeViewItem labelTitle={LBL__ENTITY_TYPE} lastItem={true}>
        <Dropdown
          placeholder=""
          options={entityTypes.US}
          selected={entityType}
          onSelect={handleEntityTypeChange}
          id="entity_type"
          dropdownSize={styles.dropdown}
        />
        {/* <div className={styles.other_state}>
          <div className={styles.other_state_question}>
            {LBL__ENTITY_IN_OTHER_STATE}
          </div>
          <div className={styles.radio_options}>
            <InputRadio
              selected={otherStateValue == LBL__YES}
              label={LBL__YES}
              onSelect={() => handleOtherStateSelect(LBL__YES)}
            />

            <InputRadio
              selected={otherStateValue == LBL__NO}
              label={LBL__NO}
              onSelect={() => handleOtherStateSelect(LBL__NO)}
            />
          </div>
        </div> */}
      </TreeViewItem>
    </>
  );
};

export default OnboardingCollectIncorporationDetails;
