import { useSelector } from "react-redux";
import Button from "../../UI/Button/Button";
import styles from "./Payment.module.css";
import {
  selectFilteredCartItems,
  selectCartSummary,
} from "./reducers/cart-slice";
import {
  LBL__DISCOUNT,
  LBL__PAY,
  LBL__SUB_TOTAL,
  LBL__TOTAL_PAYABLE,
} from "../../../consts/TextConstants";
import {
  URL__STRIPE_SUCCESS_REDIRECT,
  URL__STRIPE_FAILURE_REDIRECT,
} from "../../../consts/APIConstants";
import { useCheckoutCartMutation } from "./apis/cartApi-slice";
import { setStripeRedirectUrls, removeStripeRedirectUrls } from "../../../utils/authentication";
import { absolutePaths } from "../../../consts/PathConstants";

const Payment = () => {
  const { totalAmount, discountAmount, totalPayable } =
    useSelector(selectCartSummary);
  const { discountCode, cartItems, selectedEntity } = useSelector(
    (state) => state.cart
  );

  const isCartEmpty = cartItems.length === 0;

  const [checkoutCart, { isLoading: loadingCheckoutCart }] =
    useCheckoutCartMutation();

  const handleCheckout = async () => {
    try {
      const requestBody = {
        entity_id: selectedEntity?.entityId,
        discount_code: discountCode,
        success_url: URL__STRIPE_SUCCESS_REDIRECT,
        cancel_url: URL__STRIPE_FAILURE_REDIRECT,
      };
      setStripeRedirectUrls(absolutePaths.COMPLIANCE, absolutePaths.SHOP, {
        complianceEntity: selectedEntity,
      });

      const response = await checkoutCart(requestBody).unwrap();
      const reditectUrl = response?.url;
      window.location.href = reditectUrl;
    } catch (error) {
      console.log(error);
      removeStripeRedirectUrls();
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.top_container}>
        <div className={styles.card}>
          <div className={styles.price}>
            <p>{LBL__SUB_TOTAL}</p>
            <p>{isCartEmpty ? "$0.00" : `$${totalAmount.toFixed(2)}`}</p>
          </div>

          {/* <div className={styles.discount}>
            <div className={styles.discount_type_cont}>
              <p>{LBL__DISCOUNT} </p>
              <p className={styles.discount_type}>
                {isCartEmpty ? "" : discountCode}
              </p>
            </div>
            <p>{isCartEmpty ? "-" : `$${discountAmount.toFixed(2)}`}</p>
          </div> */}
        </div>

        <div className={styles.total_bill}>
          {LBL__TOTAL_PAYABLE}
          <p className={styles.total_price}>
            {isCartEmpty ? "$0.00" : `$${totalPayable.toFixed(2)}`}
          </p>
        </div>
      </div>

      <div>
        <Button
          buttonstyle="default-blue"
          className={`${styles.btn_pay} ${isCartEmpty ? styles.disabled : ""}`}
          onClick={handleCheckout}
          disabled={isCartEmpty || loadingCheckoutCart}
          isLoading={loadingCheckoutCart}
        >
          {LBL__PAY}
        </Button>
      </div>
    </div>
  );
};

export default Payment;
