import { useSelector } from "react-redux";
import ListCartItems from "./ListCartItems";
import Payment from "./Payment";
import styles from "./CartBody.module.css";

const CartBody = () => {
  const { cartItems } = useSelector((state) => state.cart);
  return (
    <div className={styles.container}>
      <ListCartItems />
      <Payment />
    </div>
  );
};

export default CartBody;
