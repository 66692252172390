export const paths = {
  HOME: "home",
  SHOP: "shop",
  COMPLIANCE: "compliance",
  FINANCE: "finance",
  SERVICES: "services",
  DOCUMENTS: "documents",
  NEEDHELP: "needhelp",
  TEAM: "team",
  ENTITIES: "entities",
  ENTITY_DETAILS: "entities/:id",
  GOOGLE_LOGIN: "/auth/callback/google",
  ZOHO_CALLBACK: "/zoho/callback",
  CREATE_PASSWORD: "createpassword",
  SIGNUP: "signup",
  CHAT: "chat",
  STRIPE_SUCCESS: "callback/stripe-success",
  STRIPE_FAILURE: "callback/stripe-failure",
};

const buildPath = (...segments) => `/${segments.join("/")}`;

export const absolutePaths = {
  LOGIN: "/",
  HOME: buildPath(paths.HOME),
  SHOP: buildPath(paths.HOME, paths.SHOP),
  COMPLIANCE: buildPath(paths.HOME, paths.COMPLIANCE),
  FINANCE: buildPath(paths.HOME, paths.FINANCE),
  SERVICES: buildPath(paths.HOME, paths.SERVICES),
  DOCUMENTS: buildPath(paths.HOME, paths.DOCUMENTS),
  NEEDHELP: buildPath(paths.HOME, paths.NEEDHELP),
  TEAM: buildPath(paths.HOME, paths.TEAM),
  ENTITIES: buildPath(paths.HOME, paths.ENTITIES),
  ENTITY_DETAILS: buildPath(paths.HOME, paths.ENTITY_DETAILS),
  STRIPE_SUCCESS: buildPath(paths.STRIPE_SUCCESS),
  STRIPE_FAILURE: buildPath(paths.STRIPE_FAILURE),
};
