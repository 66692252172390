import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useGetComplianceDataQuery } from "../apis/subscriptionsApi-slice";
import OverlayRegular from "../../../UI/Modal/OverlayRegular";
import styles from "./AddTaxComplianceOverlay.module.css";
import TaxComplianceForm from "./TaxComplianceForm";
import DescriptionForm from "./DescriptionForm";
import LoadingComponent from "../../../UI/LoadingIndicator/LoadingComponent";
import ErrorComponent from "../../../UI/Error/ErrorComponent";

const AddTaxComplianceOverlay = ({
  shortDescription,
  onClose,
  complianceId,
}) => {
  const { selectedCompliance } = useSelector((state) => state.subscriptions);
  // Fetch compliance data using the RTK Query hook
  const {
    isError,
    isFetching: isLoading,
    isSuccess,
  } = useGetComplianceDataQuery(
    { compliance_id: complianceId },
    { refetchOnMountOrArgChange: true }
  );

  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "unset";
    };
  }, []);

  let content;
  // Handling loading state
  if (isLoading) {
    content = <LoadingComponent type="default" />;
  }

  // Handling error state
  if (isError) {
    content = (
      <ErrorComponent
        errorMessage="Error fetching compliance data"
        className={styles.error_container}
      />
    );
  }

  if (isSuccess) {
    content = (
      <>
        <div className={styles.body_container}>
          <DescriptionForm
            complianceDetailedDescription={
              selectedCompliance?.detailedDescription
            }
          />
          <TaxComplianceForm onClose={onClose} />
        </div>
      </>
    );
  }

  return (
    <OverlayRegular onClose={onClose}>
      <div className={styles.container}>
        <div className={styles.header_container}>
          <div>
            <h2 className={styles.title_text}>
              {selectedCompliance?.complianceName}
            </h2>
            <p className={styles.short_para}>{shortDescription}</p>
          </div>
          <div className={styles.price_container}>
            <p className={styles.price}>${selectedCompliance?.price}</p>
          </div>
        </div>
        {content}
      </div>
    </OverlayRegular>
  );
};

export default AddTaxComplianceOverlay;
