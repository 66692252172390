import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
} from "react-router-dom";
import LoginPage from "../pages/Login";
import DashboardPage from "../pages/Dashboard";
import RootPage from "../pages/Root";
import SignUpPage from "../pages/SignUp";
import CreatePassword from "../pages/CreatePassword.jsx";
import EntitiesPage from "../pages/Entities.jsx";
import TentativePage from "../pages/Tentative.jsx";
import RequiredAuth from "../pages/RequiredAuth.jsx";
import CompliancePage from "../pages/Compliance.jsx";
import GoogleLoginPage from "../pages/GoogleLogin.jsx";
import FinancePage from "../pages/Finance.jsx";
import ZohoBooksPage from "../pages/ZohoBooks.jsx";
import ServicesPage from "../pages/Services.jsx";
import ErrorPage from "../pages/Error.jsx";
import EntityDetailsPage from "../pages/EntityDetails.jsx";
import DocumentsPage from "../pages/Documents";
import StripeSuccessPage from "../pages/StripeSuccess.jsx";
import StripeFailurePage from "../pages/StripeFailure.jsx";
import SessionProvider from "../pages/SessionProvider.jsx";
import SubscriptionsPage from "../pages/Subscriptions.jsx";
import { paths, absolutePaths } from "../consts/PathConstants.jsx";

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<SessionProvider />} errorElement={<ErrorPage />}>
      <Route path="/" element={<RootPage />}>
        <Route
          index
          element={<LoginPage />}
        // action={userLoginAction}
        />
        <Route path={paths.SIGNUP} element={<SignUpPage />} />
        <Route path={paths.CREATE_PASSWORD} element={<CreatePassword />} />
        <Route path={paths.GOOGLE_LOGIN} element={<GoogleLoginPage />} />
        <Route path={paths.ZOHO_CALLBACK} element={<ZohoBooksPage />} />
        <Route
          path={absolutePaths.STRIPE_SUCCESS}
          element={<StripeSuccessPage />}
        />
        <Route
          path={absolutePaths.STRIPE_FAILURE}
          element={<StripeFailurePage />}
        />

        <Route path={paths.HOME} element={<RequiredAuth />}>
          <Route index element={<DashboardPage />} />
          <Route path={paths.ENTITIES} element={<EntitiesPage />} />
          <Route path={paths.ENTITY_DETAILS} element={<EntityDetailsPage />} />
          <Route path={paths.COMPLIANCE} element={<CompliancePage />} />
          <Route path={paths.FINANCE} element={<FinancePage />} />
          <Route path={paths.SERVICES} element={<ServicesPage />} />
          <Route path={paths.SHOP} element={<SubscriptionsPage />} />
          <Route path={paths.CHAT} element={<TentativePage />} />
          <Route path={paths.DOCUMENTS} element={<DocumentsPage />} />
          <Route path={paths.NEEDHELP} element={<TentativePage />} />
        </Route>
      </Route>
    </Route>
  )
);

export default router;
