import { apiSlice } from "../../../../store/api-slice";
import {
  URL__GET_ALL_COMPLIANCE_LIST,
  METHOD_GET,
  METHOD_POST,
  URL__GET_COMPLIANCE_BY_ID,
  URL__CREATE_COMPLIANCE_SUBSCRIPTION,
} from "../../../../consts/APIConstants";
import { subscriptionsActions } from "../reducers/subscriptionsSlice";

const subscriptionsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // Endpoint to get compliance data based on compliance ID
    getComplianceData: builder.query({
      // This will fetch compliance data based on the compliance ID
      query: (args) => ({
        url: URL__GET_COMPLIANCE_BY_ID,
        method: METHOD_GET,
        params: args,
      }),
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(subscriptionsActions.setSelectedCompliance(data));
          // Here you can dispatch the fetched data to store if needed
          // Example: dispatch(serviceActions.setComplianceData(data));
        } catch (error) {
          console.error("Error fetching compliance data:", error);
          dispatch(subscriptionsActions.setSelectedCompliance(null));
        }
      },
    }),

    // Endpoint to get a list of all compliances
    getAllComplianceList: builder.query({
      query: (args) => {
        return {
          url: URL__GET_ALL_COMPLIANCE_LIST,
          method: METHOD_GET,
          params: args,
        };
      },
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(subscriptionsActions.setComplianceList(data));
        } catch (error) {
          dispatch(subscriptionsActions.setComplianceList([]));
        }
      },
    }),
    createComplianceSubscription: builder.mutation({
      query: (data) => {
        return {
          url: URL__CREATE_COMPLIANCE_SUBSCRIPTION,
          method: METHOD_POST,
          body: data,
        };
      },
    }),
  }),
});

export const {
  useGetComplianceDataQuery,
  useGetAllComplianceListQuery,
  useCreateComplianceSubscriptionMutation,
} = subscriptionsApiSlice;
