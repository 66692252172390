import { useState } from "react";
import ButtonCustom from "../../../UI/Button/ButtonCustom";
import OverlayRegular from "../../../UI/Modal/OverlayRegular";
import styles from "./AddOverlayServices.module.css";
import ServiceForm from "./ServiceForm";
import GetQuoteOverlay from "./GetQuoteOverlay";

const AddServiceOverlay = ({ onClose, title, price }) => {
    const [formData, setFormData] = useState({});
    const [showGetQuoteOverlay, setShowGetQuoteOverlay] = useState(false);
    const [showErrors, setShowErrors] = useState(false); // Track if errors should be shown

    const handleInputChange = (field, value) => {
        setFormData((prevData) => ({ ...prevData, [field]: value }));
    };

    const handleGetQuoteBtnClick = () => {
        setShowErrors(true); // Show errors after clicking "GET QUOTE"
        if (formData.isFormValid) {
            setShowGetQuoteOverlay(true);
        }
    };

    const handleAddPayClick = () => {
        setShowErrors(true); // Show errors after clicking "ADD & PAY"
        if (formData.isFormValid) {
            onClose();
        }
        // No alert, just error state controls the error display
    };

    const closeGetQuoteOverlay = () => {
        setShowGetQuoteOverlay(false);
        onClose();
    };

    return (
        <>
            <OverlayRegular onClose={onClose}>
                <div className={styles.container}>
                    <div className={styles.header_container}>
                        <h1 className={styles.title}>{title}</h1>
                        <p className={styles.para}>Select entity to add service</p>
                    </div>

                    <div className={styles.middle_container}>
                        <ServiceForm
                            title={title}
                            formData={formData}
                            handleInputChange={handleInputChange}
                            showErrors={showErrors}  // Pass showErrors to control error display
                        />
                    </div>

                    <div className={styles.footer_container}>
                        {price === null ? (
                            <ButtonCustom
                                className={styles.btn__pay}
                                onClick={handleGetQuoteBtnClick}
                            // disabled={!formData.isFormValid}
                            >
                                GET QUOTE
                            </ButtonCustom>
                        ) : (
                            <ButtonCustom
                                className={styles.btn__pay}
                                onClick={handleAddPayClick}
                            // disabled={!formData.isFormValid}
                            >
                                ADD & PAY {price}
                            </ButtonCustom>
                        )}
                    </div>
                </div>
            </OverlayRegular>

            {showGetQuoteOverlay && <GetQuoteOverlay onClose={closeGetQuoteOverlay} />}
        </>
    );
};

export default AddServiceOverlay;